import { lazy, Suspense } from "react";
import {
  useLocation,
  BrowserRouter as Router,
  useRoutes,
  Navigate,
    Routes,
    Route,
  } from "react-router-dom";

// Guards


// layouts


// configs


import ChoiraLoader from "./components/loader/ChoiraLoader";
import AllStudioPageDetailsPage from "./pages/admin/studios/AllStudioPageDetailsPage.jsx";
import AddNewStudio from "../src/components/adminStudio/AddNewStudio.jsx";
import AddNewProduction from "../src/components/adminStudio/appsAndMore/AddNewProduction.jsx";
import PartnerLogin from "./pages/partner/auth/PartnerLogin.jsx";
import dynamicNav from "./utils/dynamicNav.js";
import PageNotFound from "./pages/pageNotFound/PageNotFound.jsx";
import AddNewEntity from "./components/entity/AddNewEntity.jsx";


const Signin = lazy(() => import("./pages/admin/auth/Signin.jsx"));
const Signup = lazy(() => import("./pages/admin/auth/Signup.jsx"));
const Project = lazy(() => import("./pages/home/Project.jsx"));
const Dashboard = lazy(() => import("./pages/produce/Dashboard.jsx"));
const NewProject = lazy(() => import("./pages/produce/NewProject.jsx"));
const ChoiraTest = lazy(() => import("./pages/produce/ChoiraTest.jsx"));
const AddNewCategories = lazy(() => import("./components/categories/AddNewCategories.jsx"));
          {/* admin pagestart ========================> */}
const Studios = lazy(() => import("./pages/admin/studios/Studios.jsx"));

const AdminDashboardLayout = lazy(() => import("./pages/admin/layout/AdminDashboardLayout.jsx"));

const ShowBookingsDetail = lazy(()=> import("../src/components/adminStudio/booking/ShowBookingDetails.jsx"))
const SlotBooking = lazy(()=> import("../src/components/adminStudio/SlotBooking.jsx"))

const AddNewStudioPartners = lazy(()=> import("../src/components/teams/AddNewStudioPartners.jsx"))
const AddNewArm = lazy(()=> import("../src/components/teams/AddNewArm.jsx"))




          {/* admin page  ends ========================> */}


const Routing = () => {
  return (
    // <Router>
      <Suspense fallback={<ChoiraLoader/>}>
        <Routes>
        <Route exact path="/" element={<Navigate to="/signin" />} />
          <Route exact path='/signin' element={<Signin/>} />
          <Route exact path='/signup' element={<Signup/>} />
          <Route exact path='/project' element={<Project/>} />
          <Route exact path='/dashboard' element={<Dashboard/>} />5
          <Route exact path='/newproject' element={<NewProject/>} />
          <Route exact path='/choiratest' element={<ChoiraTest/>} />
  
          <Route exact path='/signup' element={<Signup/>}/>

          {/* admin page route start ========================> */}

          <Route exact path='/studios' element={<Studios/>} />
          <Route exact path='/allStudioPageDetailsPage' element={<AllStudioPageDetailsPage/>} />
          <Route path={`/${dynamicNav}/:navOption/:page`} element={<AdminDashboardLayout/>} />
          <Route path={`/${dynamicNav}/:navOption`} element={<AdminDashboardLayout/>} />
          <Route path={`/${dynamicNav}`} element={<AdminDashboardLayout/>} />
          <Route path={`/partner-dashboard/Overview`} element={<AdminDashboardLayout/>} />
          <Route exact path='/studio/edit' element={<AddNewStudio/>} />
          <Route exact path='entity/add' element={<AddNewEntity/>} />
          <Route exact path='entity/edit' element={<AddNewEntity/>} />
          <Route exact path='/studio/add' element={<AddNewStudio/>} />
          <Route exact path={`/${dynamicNav}/Categories/add`} element={<AddNewCategories/>} />
          <Route exact path={`/${dynamicNav}/Categories/edit`} element={<AddNewCategories/>} />

          <Route exact path='/service/musicProduction/edit' element={<AddNewProduction/>} />
          <Route exact path='/service/musicProduction/add' element={<AddNewProduction/>} />
          <Route exact path='/service/showBookingDetails' element={<ShowBookingsDetail/>} />
          <Route exact path={`/${dynamicNav}/Bookings/AddSlotBooking`} element={<SlotBooking/>} />
          <Route exact path={`/${dynamicNav}/Teams/AddStudioPatner`} element={<AddNewStudioPartners/>} />
          <Route exact path={`/${dynamicNav}/Teams/AddNewArm`} element={<AddNewArm/>} />
          <Route exact path='/partner' element={<PartnerLogin/>} />
          <Route exact path='*' element={<PageNotFound/>} />

        </Routes>
      </Suspense>
    // </Router>
  );    
};
export default Routing;
