import React, { useEffect, useState } from "react";
import Loader from "../../assets/gifs/loading.gif";
import style from "./loader2.module.css";
import nodata from "./nodataFound.png";

function ChoiraLoder2({ hasData, isLoading = true }) {
  const [noDataFound, setnoDataFound] = useState(false);

  useEffect(() => {
    const abc = setTimeout(() => {
      setnoDataFound(true);
      hasData = false;
    }, 8000);

    return () => {
      clearTimeout(abc);
    };
  }, []);
  console.log(isLoading);

  return (
    <span className={style.parent}>
      {isLoading && (
        <div
          className={style.child}
          style={{ display: noDataFound ? "none" : "flex" }}
        >
          <img
            src={Loader}
            alt="choira loading"
            style={{ width: "50px", height: "50px" }}
          />
        </div>
      )}

      {!hasData && (
        <div
          className={style.child2}
          style={{ display: noDataFound ? "flex" : "none" }}
        >
          <img
            src={nodata}
            alt="No Data Found"
            style={{ width: "90%", height: "90%" }}
          />
        </div>
      )}
    </span>
  );
}

export default ChoiraLoder2;
