import React from "react";
import style from "../../pages/admin/studios/studio.module.css";
const CustominputError = ({ error, touched }) => {
  // console.log("yeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee", error);
  return (
    <>
      {error && touched && Array.isArray(error) ? (
        error.map((errObj, index) => (
          <div key={index}>
            {Object.entries(errObj).map(([key, message]) => (
              <p className={style.error} key={key}>
                {message}
              </p>
            ))}
          </div>
        ))
      ) : error && touched && typeof error === "string" ? (
        <p className={style.error}>{error}</p>
      ) : null}
    </>
  );
};

export default CustominputError;
