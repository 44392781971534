import React from "react";
import style from "../../pages/admin/studios/studio.module.css";

function CustomInput({
  type,
  placeholder,
  name ,
  value = "",
  onChange,
  id,
  htmlFor,
  label,
  onBlur,
  error,
  touched,
  disabled,
  icon,
  iconPosition = "left" // left or right,
}) {
  return (
    <div className={style.customInput}>
      <label htmlFor={htmlFor}>{iconPosition.toLowerCase() === "left" && icon }{label} &nbsp; {iconPosition.toLowerCase() === "right" && icon}</label>
      <input
        type={type || "text"}
        id={id}
        placeholder={placeholder}
        disabled={disabled}
        onChange={onChange}
        value={value}
        name={name}
        onBlur={onBlur}
        className={disabled ? style.disabled : ""}
      />
      {error && touched ? <p className={style.error}>{error}</p> : null}
    </div>
  );
}

export default CustomInput;
