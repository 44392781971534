import { createContext } from "react";
import React, {  useContext, useState } from "react";

const AccessContext = createContext();
export  {AccessContext};


// Create a context
const BookingPageContext = createContext();

// Provider component
export const BookingPageProvider = ({ children }) => {
  const [bookingPageCount, setBookingPageCount] = useState("c0");

  return (
    <BookingPageContext.Provider value={{ bookingPageCount, setBookingPageCount }}>
      {children}
    </BookingPageContext.Provider>
  );
};