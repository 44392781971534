import React, { useEffect, useState } from "react";
import cross from "../../../assets/cross.svg";
import style from "../../../pages/admin/studios/studio.module.css";
import { MdAddAPhoto, MdOutlineAddBox } from "react-icons/md";
import { FaPencilAlt } from "react-icons/fa";
import { FaEye } from "react-icons/fa";
import { set } from "react-ga";

function AddMultipleListing({
  rooms,
  setrooms,
  data,
  isEditMode,
  setshowRoomsDetails,
  setIndexofrooms,
  showMode,
  studioDetails,
  values,
  setFieldValue,
}) {
  // useEffect(() => {
  //   console.log("-------------------------------------", rooms);
  // }, [rooms]);

  // useEffect(() => {
  //   console.log(">>>>>>>>>>>>>>>", rooms);
  // }, []);

  const handleAddTeamDetail = () => {
    const newTeam = {
      listingId: null,
      listingName: "",
      listingPhotos: [],
      area: "", //ex
      seats: 0,
      sessionHours: 0,
      price: 0,
      basePrice: 0,
      discountPercentage: 0,
      amenities: [],
      // approval: "Instant",
      bookingTypes: "",
      // bookingConfirmation: "",
      // roomDetails: "", //ex
      generalTime: {
        startTime: "",
        endTime: "",
      },
      bookingDays: [
        {
          day: "",
          date: "",
          startTime: "",
          endTime: "",
        },
      ],
    };
    setrooms([...rooms, newTeam]);
    setFieldValue("listingDetails", [...values.listingDetails, newTeam]);
  };

  const handleInputChange = (event, index, field) => {
    const newTeams = [...rooms];
    newTeams[index][field] = event.target.value;
    setrooms(newTeams);
  };

  const handleCancelTeam = (index) => {
    if (rooms.length > 1) {
      const newTeams = [...rooms];
      newTeams.splice(index, 1);
      setrooms(newTeams);
    }
    if (values.listingDetails.length > 1) {
      const newTeams = [...values.listingDetails];
      newTeams.splice(index, 1);
      setFieldValue("listingDetails", newTeams);
    }
  };

  const handelEditRooms = (index) => {
    setshowRoomsDetails(true);
    setIndexofrooms(index);
  };

  const hideAddPhotoIcon = (team) => {
    // console.log("team--------", team);
    var send = team?.listingPhotos?.length ? { display: "none" } : {};
    // console.log("send", send);

    return send;
  };

  useEffect(() => {
    console.log(
      "rooom kaa studioDetails --------------------",
      studioDetails?.country
    );
  }, [studioDetails]);

  return (
    <div className={style.addTeamDetailDiv}>
      <label htmlFor="Rooms">Listing</label>
      {isEditMode ? (
        <div className={style.addTeamDetailDynamicDiv} id="Rooms">
          {values.listingDetails?.map((team, index) => (
            <div key={index} className={style.addTeamDetailMainDiv}>
              <div>
                <label>
                  <MdAddAPhoto style={hideAddPhotoIcon(team)} />
                </label>

                {team?.listingPhotos?.length ? (
                  <div>
                    <img
                      src={
                        team.listingPhotos.length
                          ? typeof team.listingPhotos[0] === "string" &&
                            team.listingPhotos[0].startsWith("http")
                            ? team.listingPhotos[0] // If `team.listingPhotos[0]` is a string URL
                            : team.listingPhotos[0] instanceof Blob
                            ? URL.createObjectURL(team.listingPhotos[0]) // If `team.listingPhotos[0]` is a Blob, create a URL for it
                            : undefined // Default to undefined if `team.listingPhotos[0]` is not a string URL or a Blob
                          : undefined // Default to undefined if `team.listingPhotos` is an empty array
                      }
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div>
                <input
                  type="text"
                  placeholder="Enter Rooms Name"
                  value={team.listingName}
                  readOnly
                  disabled
                  onChange={(event) =>
                    handleInputChange(event, index, "roomName")
                  }
                />

                <div style={{ display: "flex", alignItems: "center" }}>
                  <small
                    style={{
                      position: "absolute",
                      left: "40%",
                      fontSize: "1vmax",
                    }}
                  >
                    Price per hour &nbsp;
                    {values.listingDetails?.country == "IN"
                      ? "₹"
                      : studioDetails?.country == "JP"
                      ? "¥"
                      : studioDetails?.country == "US"
                      ? "$"
                      : "₹"}
                  </small>
                  <input
                    style={{ paddingLeft: "58%" }}
                    type="text"
                    placeholder=""
                    value={team.basePrice}
                    readOnly
                    disabled
                    onChange={(event) =>
                      handleInputChange(event, index, "basePrice")
                    }
                  />
                </div>
                <div className={style.editpencil}>
                  {showMode ? (
                    <FaEye onClick={() => handelEditRooms(index)} />
                  ) : (
                    <FaPencilAlt onClick={() => handelEditRooms(index)} />
                  )}
                </div>
              </div>
              {rooms.length > 1 && (
                <span
                  style={{ cursor: "pointer" }}
                  className={style.cancelTeamDetailUpload}
                  onClick={() => handleCancelTeam(index)}
                >
                  <img src={cross} alt="" />
                </span>
              )}
            </div>
          ))}
          <span
            className={style.addTeamDetailbtn}
            onClick={handleAddTeamDetail}
          >
            <MdOutlineAddBox /> &nbsp;<div>Add new Listing</div>
          </span>
        </div>
      ) : (
        <div className={style.addTeamDetailDynamicDiv}>
          {values.listingDetails.map((team, index) => (
            <div key={index} className={style.addTeamDetailMainDiv}>
              <div>
                <label>
                  <MdAddAPhoto style={hideAddPhotoIcon(team)} />
                </label>

                {team.listingPhotos.length ? (
                  <div>
                    <img
                      src={
                        team.listingPhotos.length
                          ? typeof team.listingPhotos[0] === "string" &&
                            team.listingPhotos[0].startsWith("http")
                            ? team.listingPhotos[0] // If `team.listingPhotos[0]` is a string URL
                            : team.listingPhotos[0] instanceof Blob
                            ? URL.createObjectURL(team.listingPhotos[0]) // If `team.listingPhotos[0]` is a Blob, create a URL for it
                            : undefined // Default to undefined if `team.listingPhotos[0]` is not a string URL or a Blob
                          : undefined // Default to undefined if `team.listingPhotos` is an empty array
                      }
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div>
                <input
                  type="text"
                  placeholder="RoomName"
                  value={team.listingName}
                  readOnly
                  disabled
                  onChange={(event) =>
                    handleInputChange(event, index, "roomName")
                  }
                />
                <div style={{ display: "flex", alignItems: "center" }}>
                  <small
                    style={{
                      position: "absolute",
                      left: "40%",
                      fontSize: "1vmax",
                    }}
                  >
                    Base Price &nbsp;
                    {values.listingDetails?.country == "IN"
                      ? "₹"
                      : studioDetails?.country == "JP"
                      ? "¥"
                      : studioDetails?.country == "US"
                      ? "$"
                      : "₹"}
                  </small>
                  <input
                    style={{ paddingLeft: "55%" }}
                    type="text"
                    placeholder=""
                    readOnly
                    disabled
                    value={team.basePrice}
                    onChange={(event) =>
                      handleInputChange(event, index, "basePrice")
                    }
                  />
                </div>
                <div className={style.editpencil}>
                  <FaPencilAlt onClick={() => handelEditRooms(index)} />
                </div>
              </div>

              {rooms.length > 1 && (
                <span
                  style={{ cursor: "pointer" }}
                  className={style.cancelTeamDetailUpload}
                  onClick={() => handleCancelTeam(index)}
                >
                  <img src={cross} alt="" />
                </span>
              )}
            </div>
          ))}
          <span
            className={style.addTeamDetailbtn}
            onClick={handleAddTeamDetail}
          >
            <MdOutlineAddBox /> &nbsp;<div>Add listing</div>
          </span>
        </div>
      )}
    </div>
  );
}

export default AddMultipleListing;
