import React, { useEffect, useRef, useState } from "react";
import { MdEdit } from "react-icons/md";
import style from "../../pages/admin/studios/studio.module.css";

import WebDashboard2 from "../../pages/produce/WebDashBoard2";

import { useLocation, useNavigate } from "react-router-dom";
import DragAndDropImageDiv from "../inputs/DragAndDropImageDiv";
import Button from "../common/Button";
import { confirmAlret, errorAlert, sucessAlret } from "../alert/Alert";

import { partnerAccess } from "../../config/partnerAccess";

import AddNewListing from "./AddNewListing";
import StudioFooter from "../adminStudio/StudioFooter";
import AddMultipleTeam from "../adminStudio/appsAndMore/AddMultipleTeam";
import CustomSelect from "../inputs/CustomSelect";
import AddMultipleListing from "../adminStudio/appsAndMore/AddMultipleListing";
import CustomInput from "../inputs/CustomInput";
import { useFormik } from "formik";
import { CategoryEntity } from "../../schemas";
import CustomDataList from "../inputs/CustomDataList";
import MultipleSelect from "../inputs/MultipleSelect";
import CustomMultipleSelect from "../inputs/CustomMultipleSelect";
import categoriesApi from "../../services/categoriesApi";
import { createEntityDemoData } from "../../utils/demo data/demoData";
import { useMutation, useQueryClient } from "react-query";

function AddNewEntity({ setSelectTab }) {
  
  const data = useLocation();
  const [isEditMode, setIsEditMode] = useState(data?.state?.isEditMode);

  const [showMode, setShowMode] = useState(data?.state?.showMode || false);
  // console.log("data---------->", data);
  let idToSearch = data.search.split("=")[1];
  // console.log("idToSearch", idToSearch);
 


  // const editData = data?.state?.productData
  const [editData, setEditData] = useState(data?.state?.productData)
;
// console.log("editData", editData);
  const formik = useFormik({
    initialValues:
      isEditMode || showMode
        ? editData
        : {
            catId: data?.state?.bookingPageCount,
            name: "",
            pincode: "",
            // area: "",
            state: "",
            address: "",
            description: "",
            photos: [],
            // capacity: "", //extra
            city: "",
            mapLink: "",
            country: "",
            facilities: [
              // {
              //   id: "",
              //   name: "",
              // },
            ],
            amenities: [
              // {
              //   id: "",
              //   name: "",
              // },
            ],
            listingDetails: [
              {
                listingId: 0,
                listingName: "",
                listingPhotos: [],
                area: "", //ex
                seats: 0,
                sessionHours: 0,
                capacity: "", 
                price: 0,
                basePrice: "",
                discountPercentage: 0,
                // approval: "Instant",
                amenities: [
                  // {
                  //   id: "",
                  //   name: "",
                  // },
                ],
                bookingTypes: "",
                // bookingConfirmation: "",
                aboutUs: "", //ex
                generalTime: {
                  startTime: "",
                  endTime: "",
                },

                bookingDays: [
                  // {
                  //   day: "",
                  //   date: "",
                  //   startTime: "",
                  //   endTime: "",
                  // },
                ],
              },
            ],
            // reviews: [],
            isActive: 1,
            // creationTimeStamp: new Date().toISOString(),
            // location: {
            //   type: "Point",
            //   coordinates: [0, 0], // Example: [latitude, longitude]
            // },
            // minPrice: {
            //   price: null,
            //   basePrice: null,
            //   discountPercentage: null,
            // },
          },
    team: [{ id: 1, imgUrl: null, name: "", designation: "" }],
    validationSchema: CategoryEntity,
    onSubmit: (values) => {
      console.log("Form Submitted", values);
      handleSubmitButtonClick(values);

      // Your API call here
      // setShowButtonLoader(true);
      // setTimeout(() => {
      //   setShowButtonLoader(false);
      //   navigate(/${dynamicNav}/Teams/StudioPartners);
      // }, 1000);
    },
  });

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    setFieldValue,
    setValues,
    handleSubmit,
  } = formik;

  useEffect(() => {
    if(data?.state?.isEditMode || data?.state?.showMode){
     setIsEditMode(true);
     categoriesApi.getCategorieEntityById(idToSearch).then((res) => {
       console.log("res", res);
      //  setEditData(res.data.data);
       setValues(res.categoriesEntity[0 ]);
       
     });
 
    }
   }, [])

  const submitButtonRef = useRef(null);
  const [images, setImages] = useState(values.photos || []);
  const [showBtnLoader, setShowBtnLoader] = useState(false);
  let loaderText = "saving ...";
  // console.log("data----------",data)
  // let test = ();

  const [selectedFacilities, setSelectedFacilities] = useState(values.facilities.map((item) => item.name ||item)|| []);

  useEffect(() => {
    setIsEditMode(data.state?.isEditMode);
  }, []);

  const navigate = useNavigate();
  const gotoadminpage = () => {
    navigate(-1);
  };

  // console.log("the data id  ================== >", data?.state?.productData);

  const userStudioid = data?.state?.productData?._id;
  const [tabCount, setTabCount] = useState();
  const [showRoomsDetails, setshowRoomsDetails] = useState(false);
  const [indexofrooms, setIndexofrooms] = useState();
  const [entity, setEntity] = useState("");

  const [rooms, setrooms] = useState([
    {
      listingId: null,
      listingName: "",
      listingPhotos: [],
      // area: "", //ex
      seats: 0,
      sessionHours: 0,
      price: null,
      basePrice: null,
      discountPercentage: null,
      approval: "Instant",
      bookingTypes: "",
      // bookingConfirmation: "",
      amenities: [],

      // aboutUs: "", //ex
      generalTime: {
        startTime: "",
        endTime: "",
      },
      tempBookingDays: "",
      bookingDays: [
        {
          day: "",
          date: "",
          startTime: "",
          endTime: "",
        },
      ],
    },
  ]);
  const [teamDetails, setTeamsDetails] = useState(
    values.teamDetails ||
    [
    { id: 1, imgUrl: null, name: "", designation: "" },
  ]);
  const [selectedStudioAmenities, setSelectedStudioAmenities] = useState(
    values?.amenities.map((item)=>item.name || item) || []
  );

  useEffect(() => {
    if (selectedStudioAmenities.length > 0) {
      let temp = selectedStudioAmenities?.map((data, index) => {
        return {
          id: String(index),
          name: data,
        };
      });
      setFieldValue("amenities", temp);
    }
  }, [selectedStudioAmenities]);

  useEffect(() => {
    setFieldValue("photos", images);
  }, [images]);

  const changeMode = () => {
    setIsEditMode(true);
    setShowMode(false);

    sucessAlret("edit mode on");
  };

  const queryClient = useQueryClient();

  const addEntityMutation = useMutation(categoriesApi.addNewEntity, {
    onSuccess: (response) => {
      setShowBtnLoader(false);
      console.log("Response from API:", response);
      if (response.status) {
        sucessAlret("Entity added successfully");
        queryClient.invalidateQueries(['categories']); // Update the relevant query key
        navigate("/adminDashboard/Apps&More/studio");
      } else {
        console.error("Error:", response?.data?.message);
      }
    },
    onError: (error) => {
      setShowBtnLoader(false);
      console.error("Add entity error:", error);
      errorAlert(error);
    },
  });

  // Mutation for updating an entity
  const updateEntityMutation = useMutation(
    ({ id, data }) => categoriesApi.updateCategoryEntity(id, data),
    {
      onSuccess: (response) => {
        setShowBtnLoader(false);
        console.log("Response from API:", response);
        if (response.status) {
          sucessAlret("Entity updated successfully");
          queryClient.invalidateQueries(['categories']); // Update the relevant query key
          navigate("/adminDashboard/Apps&More/studio");
        } else {
          console.error("Error:", response?.data?.message);
        }
      },
      onError: (error) => {
        setShowBtnLoader(false);
        console.error("Update entity error:", error);
      errorAlert(error);

      },
    }
  );
  const handleSubmitButtonClick = (values) => {
    confirmAlret()
      .then((res) => {
        if (res.isConfirmed) {
          let actualData = JSON.parse(JSON.stringify(values));

          // Process listing details
          actualData.listingDetails.forEach((data) => {
            // Remove unnecessary fields
            delete data.tempBookingDays;
            // delete data.aboutUs;

            // Clean up bookingDays array
            data.bookingDays.forEach((date) => {
              delete date.id; // Ensure this targets the correct level
            });

            // Convert bookingTypes to an array of values
            console.log(
              "data?.bookingTypes?.map((type) => type?.value)",
              data.bookingTypes
            );
            data.bookingTypes = data?.bookingTypes?.map((type) => type?.value ||type);
          });
          actualData.teamDetails.forEach((data) => {
            delete data.photo;
          });

          // Handle further processing or API calls with `actualData`
          console.log("Processed data:", actualData);

          // Example API call:
          // api.submitData(actualData).then((response) => console.log(response));
if(!isEditMode){
  setShowBtnLoader(true);
  addEntityMutation.mutate(actualData);


  
}else{
  const dataToUpdate = { ...actualData };
      delete dataToUpdate._id;
      setShowBtnLoader(true);
      updateEntityMutation.mutate({ id: idToSearch, data: dataToUpdate });
}
          
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    if (selectedFacilities && Array.isArray(selectedFacilities)) {
      setFieldValue(
        "facilities",
        selectedFacilities.map((data, index) => ({
          id: String(index),
          name: data,
        }))
      );
    }
  }, [selectedFacilities]);
  useEffect(() => {
    setFieldValue("teamDetails", teamDetails);
  }, [teamDetails]);

  useEffect(() => {
    console.log("the errors are ================>", errors);
    console.log("the Values are ================>", values);
  }, [errors, values]);
  const addDemoData = () => {
    setValues(createEntityDemoData);
  };

  return (
    <>
      <div className={style.wrapper}>
        <WebDashboard2
          navCount={partnerAccess ? 2 : 5}
          tabCount={tabCount}
          setTabCount={setTabCount}
        />
        <form className={style.studioMainScreen} onSubmit={handleSubmit}>
          {showRoomsDetails ? (
            <AddNewListing
              setshowRoomsDetails={setshowRoomsDetails}
              isEditMode={isEditMode}
              rooms={rooms}
              setrooms={setrooms}
              setIndexofrooms={setIndexofrooms}
              indexofrooms={indexofrooms}
              showMode={showMode}
              values={values}
              errors={errors}
              touched={touched}
              setFieldValue={setFieldValue}
              handleChange={handleChange}
              handleBlur={handleBlur}
              handleSubmit={handleSubmit}
            />
          ) : (
            <>
              <div className={style.addNewStudioTitle}>
                {isEditMode && showMode
                  ? " details"
                  : isEditMode
                  ? "Edit details"
                  : "Add new "}

                {showMode && (
                  <Button
                    name={" Edit"}
                    icon={<MdEdit />}
                    style={{ height: "50%", fontSize: "0.8vmax", gap: "5%" }}
                    onClick={changeMode}
                  />
                )}

                {/* {!isEditMode && !showMode && (<Button
                  name={"Add Demo Data"}
                  onClick={addDemoData}
                  type={"button"}
                  style={{ height: "50%", fontSize: "0.8vmax" }}
                />)} */}

                
              </div>

              <div
                className={style.addNewStudioPage}
                // style={{ overflow: "hidden" }}
                style={{
                  // border: "1px solid red",
                  overflow: "hidden",
                  paddingBottom: "5vmax",
                }}
              >
                <div
                  style={{
                    position: showMode ? "relative" : "",
                    // overflow: "hidden",
                    height: "fit-content",
                    // border: "1px solid blue",
                  }}
                >
                  {showMode ? <p className={style.showmode}></p> : ""}

                  <div>
                    <CustomInput
                      type="text"
                      id="studioName"
                      placeholder="Enter  Name"
                      name="name"
                      label={"Name"}
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.name}
                      touched={touched.name}
                    />

                    <CustomInput
                      type="number"
                      id="area"
                      placeholder="Enter Approx. Area"
                      name="area"
                      label={"Space Area"}
                      value={values.area}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.area}
                      touched={touched.area}
                    />

                    <CustomInput
                      type="string"
                      id="pincode"
                      name="pincode"
                      placeholder="Enter Pincode"
                      label={"Pincode"}
                      value={values.pincode}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.pincode}
                      touched={touched.pincode}
                    />

                    <CustomDataList
                      label="Select State"
                      list={"State"}
                      id="state"
                      placeholder="Select state Name"
                      name="state"
                      value={values.state}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.state}
                      touched={touched.state}
                      options={["Maharasatra", "Delhi", "Punjab", "Gujrat"]}
                    />
                    <CustomInput
                      label={"Full Address"}
                      placeholder={"Enter Full Area"}
                      type="text"
                      id="address"
                      name="address"
                      value={values.address}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.address}
                      touched={touched.address}
                    />

                    <div
                      className={style.addNewStudioinputBox2}
                      style={{ position: "relative" }}
                    >
                      <label htmlFor="aboutUs">About</label>
                      <textarea
                        type="text"
                        id="aboutUs"
                        placeholder="Enter About Entity"
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.description}
                        // touched={touched.description}
                      />
                      {errors.description && touched.description ? (
                        <p className={style.error}>{errors?.description}</p>
                      ) : null}
                    </div>
                    <div className={style.addNewStudioinputBox}>
                      <AddMultipleListing
                        rooms={rooms}
                        setrooms={setrooms}
                        setshowRoomsDetails={setshowRoomsDetails}
                        showRoomsDetails={showRoomsDetails}
                        indexofrooms={indexofrooms}
                        isEditMode={isEditMode}
                        setIndexofrooms={setIndexofrooms}
                        showMode={showMode}
                        values={values}
                        setFieldValue={setFieldValue}

                        // studioDetails={studioDetails}
                      />
                    </div>
                  </div>
                  <div>
                    <DragAndDropImageDiv
                      name={"photos"}
                      images={images}
                      setImages={setImages}
                      isEditMode={isEditMode}
                      showMode={showMode}
                      error={errors.photos}
                      touched={touched.photos}
                    />

                    {/* <CustomSelect
                      name="capacity"
                      label={"Artist Capacity (Artist)"}
                      value={values.capacity}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.capacity}
                      touched={touched.capacity}
                      defaultOption={"Select Capacity"}
                      options={["1", "2", "3", "4", "5", "6", "7", "8"]}
                    /> */}

                    <CustomDataList
                      label=" City"
                      list="city"
                      id="addcity"
                      placeholder="Select city Name"
                      name="city"
                      value={values.city}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.city}
                      touched={touched.city}
                      options={["Mumbai", "Pune", "Nagpur"]}
                    />

                    <CustomSelect
                      label="Select Country"
                      list="city"
                      id="addcity"
                      placeholder="Select city Name"
                      name="country"
                      value={values.country}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.country}
                      touched={touched.country}
                      options={{ India: "IN", Japan: "JP", USA: "US" }}
                      defaultOption={"Select Country"}
                    />

                    <CustomInput
                      type="text"
                      id="mapLink"
                      placeholder="Enter Studio MapLink"
                      name={"mapLink"}
                      label={"Studio MapLink"}
                      value={values.mapLink}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.mapLink}
                      touched={touched.mapLink}
                    />

                    <MultipleSelect
                      selectedItems={selectedStudioAmenities}
                      setSelectedItems={setSelectedStudioAmenities}
                      onBlur={handleBlur}
                      name={"amenities"}
                      error={errors.amenities}
                      touched={touched.amenities}

                      // value={values.amenities}
                    />

                    <CustomMultipleSelect
                      label={"Facilities"}
                      htmlFor={"facilitiess"}
                      id={"facilities"}
                      name={"facilities"}
                      // onChange={(selected) =>
                      //   setFieldValue("facilities", selected)

                      // }
                      optionsList={["AC", "Parking", "Wifi"]}
                      selectedItems={selectedFacilities}
                      setSelectedItems={setSelectedFacilities}
                      // setSelectedItems={setSelectedFacilities}
                      error={errors.facilities}
                      touched={touched.facilities}
                      onBlur={handleBlur}
                      // value={values.facilities}
                    />

                    <div className={style.addNewStudioinputBox}>
                      <AddMultipleTeam
                        teamDetails={teamDetails}
                        setTeamsDetails={setTeamsDetails}
                        data={data}
                        isEditMode={isEditMode}
                        showMode={showMode}
                        values={values}
                        errors={errors}
                        touched={touched}
                        setFieldValue={setFieldValue}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        handleSubmit={handleSubmit}
                      />
                    </div>
                  </div>
                </div>
                <button style={{ display: "none" }} ref={submitButtonRef}>
                  submit
                </button>
                {/* <p className={style.showmode}></p> */}
              </div>
              <StudioFooter
                setSelectTab={setSelectTab}
                backOnclick={gotoadminpage}
                backType={"button"}
                saveType={"submit"}
                // saveOnclick={showMode ? "" : ""}
                saveDisabled={showMode}
                loaderText={loaderText}
                showBtnLoader={showBtnLoader}
              />
            </>
          )}
        </form>
      </div>
    </>
  );
}

export default AddNewEntity;
