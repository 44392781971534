
export const clearEmptyField = (data) => {
  // This function clears empty fields from the object
  Object.keys(data).forEach((key) => {
    if (data[key] === "" || data[key] === undefined) {
      delete data[key];
    }
  });
}

export  const validateEmptyField = (checkData) => {
  let hasError = false;
  const errorFields = [];

  const errorAlert = (message) => {
    // Placeholder for your error alert function
    console.error(message); 
  };

  const isEmpty = (value) => {
    return (
      value === null ||
      value === "" ||
      (Array.isArray(value) && value.length === 0) ||
      (typeof value === "object" &&
        !Array.isArray(value) &&
        value !== null &&
        Object.keys(value).length === 0)
    );
  };

  const check = (data) => {
    for (const key of Object.keys(data)) {
      const value = data[key];

      if (isEmpty(value)) {
        errorAlert(`${key} field is empty`);
        hasError = true; // Set hasError to true if an empty field is found
        errorFields.push(key); // Collect the field name with error
        return; // Exit on first empty field
      }

      if (
        typeof value === "object" &&
        !Array.isArray(value) &&
        value !== null
      ) {
        if (check(value)) hasError = true; // Recursively check nested objects
      }

      if (Array.isArray(value)) {
        for (const item of value) {
          if (typeof item === "object" && item !== null) {
            if (check(item)) hasError = true; // Recursively check items in arrays
          }
        }
      }
    }
    return hasError;
  };

  check(checkData);
  return { hasError, errorFields };
};



// ============usage ==============
// const result = checkEmptyFields(checkData);
//         let hasError = result.hasError;
//         console.log(`Has error: ${result.hasError}`);

//         if (hasError == true)
//           return errorAlert(`Empty fields: ${result.errorFields.join(", ")}`);

// ====================end=====================

export const getComparableTime=(timeString)=> {
  try {
    const [hours, minutes] = timeString.split(":").map(Number);
    const date = new Date(1970, 0, 1, hours, minutes);
    return date.getTime();
  } catch (error) {
    console.error("Error parsing time:", error);
    return null;
  }
  
}

export const  deleteKey =(obj,arrOfKeys)=>{
  arrOfKeys.map((key)=>{
    delete obj[key]
    
})
    return obj
}

export const compareObj = (obj1, obj2) => {
  // Check if either is null or not an object
  console.log(obj1, "-----", obj2)

  if (obj1 == null || obj2 == null || typeof obj1 !== "object" || typeof obj2 !== "object") {
    return false;
  }

  // Get the keys of both objects
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  // Check if the number of keys is different
  if (keys1.length !== keys2.length) return false;

  // Recursively check each key and its value
  return keys1.every((key) => {
    // Ensure the key exists in obj2 and compare the values recursively
    if (!(key in obj2)) return false;

    const val1 = obj1[key];
    const val2 = obj2[key];

    // If both values are objects, recurse; otherwise, compare the values directly
    if (typeof val1 === "object" && typeof val2 === "object") {
      return compareObj(val1, val2);
    } else {
      return val1 === val2;
    }
  });
};



