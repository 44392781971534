import React, { useEffect, useState } from "react";
import { Skeleton } from "antd";
import style from "./loader2.module.css";
import nodata from "./nodataFound.png";
const CustomSkeletonTable = ({ columnCount = 7, showAvatar = true }) => {
  const [noDataFound, setnoDataFound] = useState(false);

  useEffect(() => {
    const abc = setTimeout(() => {
      setnoDataFound(true);
    }, 8000);

    return () => {
      clearTimeout(abc);
    };
  }, []);
  // Render a single cell
  const renderCell = (columnIndex) => {
    if (columnIndex === 1 && showAvatar) {
      // Second column with avatar
      return (
        <td
          key={`col-${columnIndex}`}
          style={{ display: "flex", alignItems: "center", gap: "8px" }}
        >
          <Skeleton.Avatar
            active
            size="large"
            style={{
              width: "2.5vw",
              height: "2.5vw",
              borderRadius: 4,
            }}
          />
          <Skeleton.Input size="large" active style={{ width: "5%" }} />
        </td>
      );
    }

    // Other columns
    return (
      <td key={`col-${columnIndex}`}>
        <Skeleton.Input active />
      </td>
    );
  };

  // Render a single row
  const renderRow = (rowIndex) => (
    <tr key={`row-${rowIndex}`}>
      {Array.from({ length: columnCount }).map((_, columnIndex) =>
        renderCell(columnIndex)
      )}
    </tr>
  );
  const noData = () => (
    <span className={style.parent}>
      <div
        className={style.child2}
        style={{ display: noDataFound ? "flex" : "none" }}
      >
        <img
          src={nodata}
          alt="No Data Found"
          style={{ width: "90%", height: "90%" }}
        />
      </div>
    </span>
  );

  return (
    <>
      {noDataFound ? (
        noData()
      ) : (
        <table className="custom-skeleton-table">
          <thead>{/* Optionally, render headers dynamically */}</thead>
          <tbody>
            {Array.from({ length: 7 }).map((_, rowIndex) =>
              renderRow(rowIndex)
            )}
          </tbody>
        </table>
      )}
    </>
  );
};

export default CustomSkeletonTable;
