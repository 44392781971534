import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Table, Tooltip } from "antd";
import style from "../../../pages/admin/studios/studio.module.css";
import { AiFillFilter } from "react-icons/ai";

import { MdEdit } from "react-icons/md";
import { RiDeleteBin5Fill } from "react-icons/ri";

// import Button from "../../../pages/admin/layout/Button";
import Switch from "../../common/Switch";

import imageNotFound from "../../../assets/imagesNotFound.png";

import { useNavigate } from "react-router-dom";

import ChoiraLoder2 from "../../loader/ChoiraLoder2";

import PriceFilter from "../../filters/PriceFilter";

import appAndmoreApi from "../../../services/appAndmoreApi";

import { errorAlert } from "../../alert/Alert";
import { GoEye } from "react-icons/go";
import CopyToClipboard from "../../common/CopyToClipboard ";

import { AccessContext } from "../../../utils/context";

import moment from "moment";
import DateAndSearchFilter from "../../filters/DateAndSearchFilter";
import PaginationNav from "../../pagination/PaginationNav";
import CustomSkeletonTable from "../../loader/CustomSkeletonTable ";

const AllStudioDetail2 = ({
  products,
  setProducts,
  setPageCount,
  pageCount,
  totalPage,
  bookingPageCount,
  setTotalPage,
  filterNav,
  setfilterNav,
  sendFilterDataToapi,
  perPage,
  totalResult,
}) => {
  const initialRender = useRef(true);
  const [selectedCity, setSelectedCity] = useState([]);
  const [selectedRoom, setSelectedRoom] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [priceFilter, setPriceFilter] = useState({
    minPrice: "",
    maxPrice: "",
  });
  const [shortby, setShortby] = useState("creationTimeStamp:desc");
  const navigate = useNavigate();
  const tableAccess = useContext(AccessContext);
  const [loader, setLoader] = useState(false);
  const [showloader, setShowloader] = useState(false);
  const [pid, setPid] = useState(0);

  const pageSize = 10; // You can adjust this based on your need

  let loading_timeout = null;
  const handleSwitchChange = (studioId) => {
    setShowloader(true);
    appAndmoreApi
      .updateStudioStatus(studioId)
      .then((response) => {
        console.log("response=======>", response.studio);
        setProducts((prevState) => {
          return prevState.map((product) => {
            if (product._id === studioId) {
              return {
                ...product,
                isActive: response.studio.isActive,
              };
            }
            return product;
          });
        });

        loading_timeout = setTimeout(() => {
          setShowloader(false);
        }, 700);
      })
      .catch((error) => {
        console.log("error=======>", error);
        errorAlert(error.message || "Something went wrong");
        setShowloader(false);
      });
  };

  const sendFilterDatatoapi = () => {
    setProducts([]);
    setLoader(true);
    appAndmoreApi
      .filterData(sendFilterDataToapi)
      .then((response) => {
        console.log("filter applied:", response);
        setProducts(response.studios);
        setLoader(false);
        setTotalPage(response?.paginate?.totalPages);
      })
      .catch((error) => {
        console.error("Error filter studio:", error);
        setLoader(false);
      });
  };
  const handleTableChange = (pagination, filters, sorter) => {
    console.log("filters", filters);
    sendFilterDataToapi.city = filters?.address?.[0];
    sendFilterDataToapi.totalRooms = filters?.totalRooms?.[0];
    sendFilterDataToapi.active = filters?.isActive?.[0];

    sendFilterDatatoapi();
  };
  const gotoEdit = (id) => {
    const isEditMode = true;
    const selectedProduct = products.find((product) => product._id === id);
    console.log("navigated=======>", selectedProduct);

    navigate(`/studio/edit?id=${id}`, {
      state: {
        productData: selectedProduct,
        navCount: 3,
        isEditMode: isEditMode,
      },
    });
  };
  const [showpricefilter, setshowpricefilter] = useState(false);
  const handelpriceFilter = () => {
    setshowpricefilter((prevState) => {
      if (!prevState) {
        // If toggling to true, set other filters to false
      }
      return !prevState;
    });
  };
  const closeAllFilter = () => {
    setshowpricefilter(false);
  };
  useEffect(() => {
    sendFilterDataToapi.city = selectedCity[0];
    sendFilterDataToapi.totalRooms = selectedRoom[0];
    sendFilterDataToapi.active =
      selectedStatus[0] === "active"
        ? 1
        : selectedStatus[0] === "inactive"
        ? "0"
        : "";
    sendFilterDataToapi.minPricePerHour = priceFilter.minPrice;
    sendFilterDataToapi.maxPricePerHour = priceFilter.maxPrice;
    // sendFilterDataToapi.creationTimeStamp = selectedDate;
    sendFilterDataToapi.sortBy = shortby;

    console.log(sendFilterDataToapi);
  }, [
    selectedCity,
    selectedRoom,
    selectedStatus,
    priceFilter,
    // selectedDate,
    shortby,
  ]);

  useEffect(() => {
    setProducts([]);
    if (initialRender.current) {
      initialRender.current = false;
      return; // Skip the effect on the first render
    }

    appAndmoreApi
      .filterData(sendFilterDataToapi)
      .then((response) => {
        console.log("filter applied:", response);
        setProducts(response.studios);
        setTotalPage(response?.paginate?.totalPages);
      })
      .catch((error) => {
        console.error("Error filter studio:", error);
      });

    return () => {
      setProducts([]);
    };
  }, [shortby]);
  const handelShortbyClick = () => {
    if (shortby == "creationTimeStamp:asc") {
      setShortby("creationTimeStamp:desc");
    } else {
      setShortby("creationTimeStamp:asc");
    }
  };

  const gotoShowStudioDetails = (id) => {
    const isEditMode = true;
    const selectedProduct = products.find((product) => product._id === id);
    console.log("navigated=======>", selectedProduct);
    // alert(selectedProduct);
    navigate(`/studio/edit?id=${id}`, {
      state: {
        productData: selectedProduct,
        navCount: 4,
        isEditMode: isEditMode,
        showMode: true,
      },
    });
  };
  useEffect(() => {
    return () => {
      clearTimeout(loading_timeout);
    };
  }, []);

  const columns = [
    {
      title: "Sr.No",
      dataIndex: "srNo",
      key: "srNo",
      render: (text, record, index) => {
        return shortby === "creationTimeStamp:desc"
          ? index + 1 + (pageCount - 1) * perPage
          : totalResult - pageCount * perPage + perPage - index;
      },
    },
    {
      title: "Studio",
      dataIndex: "fullName",
      key: "fullName",
      sorter: (a, b) => handelShortbyClick(),
      render: (text, record) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className={style.studioImage}>
            {record.studioPhotos ? (
              <img
                src={record.studioPhotos[0]}
                alt=""
                onError={(e) => {
                  e.target.src = imageNotFound;
                }}
              />
            ) : (
              <img src={imageNotFound} alt="" />
            )}
          </div>
          &nbsp;&nbsp;
          <CopyToClipboard textToCopy={text} />
        </div>
      ),
    },
    {
      title: (
        <div className={style.headingContainer}>
          Price
          <div
            className={style.filterBox}
            style={{
              backgroundColor:
                priceFilter.minPrice || priceFilter.maxPrice !== ""
                  ? "#ffc70133"
                  : "",
            }}
          >
            <span onClick={handelpriceFilter}>
              <AiFillFilter style={{ color: "#B1B1B1" }} />
            </span>
            {showpricefilter ? (
              <PriceFilter
                closeAllFilter={closeAllFilter}
                priceFilter={priceFilter}
                setPriceFilter={setPriceFilter}
                sendFilterDataToapi={sendFilterDataToapi}
                setProducts={setProducts}
                setTotalPage={setTotalPage}
                bookingPageCount={bookingPageCount}
                setfilterNav={setfilterNav}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      ),
      dataIndex: ["roomsDetails", "0", "pricePerHour"],
      key: "pricePerHour",
      render: (price) => (
        <span>
          ₹{price || "N/A"} <br />
          <small>per hour</small>
        </span>
      ),
    },
    {
      title: "Location",
      dataIndex: "address",
      key: "address",
      render: (address, record) => (
        <>
          <CopyToClipboard textToCopy={address} textLength={30} />
          <br />
          <small>
            <CopyToClipboard textToCopy={record.state} />
          </small>
        </>
      ),
      filters: [
        {
          text: "Mumbai",
          value: "mumbai",
        },
        {
          text: "Delhi",
          value: "Delhi",
        },
        {
          text: "Bangalore",
          value: "Bangalore",
        },
        {
          text: "Chennai",
          value: "Chennai",
        },
      ],
      filterMultiple: false,
      // specify the condition of filtering result
      // here is that finding the name started with `value`
      // onFilter: (value, record) =>
      //   console.log("valus is ", value, "record is ", record),

      // onFilter: (value, record) => (
      //   (sendFilterDataToapi.city = value), sendFilterDatatoapi()
      // ),
      reset: () => alert("Reset filters"),
    },
    {
      title: "No. of Rooms",
      dataIndex: "totalRooms",
      key: "totalRooms",
      filters: [
        {
          text: "1",
          value: "1",
        },
        {
          text: "2",
          value: "2",
        },
        {
          text: "3",
          value: "3",
        },
        {
          text: "4",
          value: "4",
        },
        {
          text: "5",
          value: "5",
        },
      ],
      filterMultiple: false,
      // specify the condition of filtering result
      // here is that finding the name started with `value`
      // onFilter: (value, record) => record.name.indexOf(value) === 0,
    },
    {
      title: "Created on",
      dataIndex: "creationTimeStamp",
      key: "creationTimeStamp",
      render: (timestamp) => moment(timestamp).format("Do MMM YY, hh:mm a"),
    },
    {
      title: "Activity Status",
      dataIndex: "isActive",
      key: "isActive",
      render: (isActive, record) => (
        <Switch
          status={record.isActive}
          isloading={pid === record._id && showloader}
          onClick={() => {
            setPid(record._id);
            handleSwitchChange(record._id);
          }}
          disabled={tableAccess?.["app&more"]?.action === "read"}
        />
      ),
      filters: [
        {
          text: "active",
          value: 1,
        },
        {
          text: "inactive",
          value: "0",
        },
      ],
      filterMultiple: false,
      // specify the condition of filtering result
      // here is that finding the name started with `value`
      // onFilter: (value, record) => record.name.indexOf(value) === 0,
    },

    {
      title: "   ",
      dataIndex: "",
      key: "",
      render: (_, record) => (
        <div>
          <Tooltip title="view">
            <GoEye
              style={{ cursor: "pointer" }}
              onClick={() => {
                gotoShowStudioDetails(record._id);
              }}
            />
          </Tooltip>
          &nbsp; &nbsp;
          <Tooltip title="Edit">
            <MdEdit
               style={{ color: "#ffc701", cursor: "pointer" }}
              onClick={() => {
                gotoEdit(record._id);
              }}
            />
          </Tooltip>
          &nbsp; &nbsp;
          <Tooltip title="Delete">
            <RiDeleteBin5Fill
              style={{  color: "red", cursor: "pointer", marginLeft: 8 }}
              onClick={() => console.log("Delete", record._id)}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className={style.studioTabelDiv}>
        <DateAndSearchFilter
          setProducts={setProducts}
          setTotalPage={setTotalPage}
          bookingPageCount={bookingPageCount}
          filterNav={filterNav}
          setfilterNav={setfilterNav}
          sendFilterDataToapi={sendFilterDataToapi}
          setSelectedCity={setSelectedCity}
          setSelectedRoom={setSelectedRoom}
          setSelectedStatus={setSelectedStatus}
          setPriceFilter={setPriceFilter}
          setShortby={setShortby}
        />
        <div>
          <Table
            columns={columns}
            dataSource={products}
            rowKey="_id"
            pagination={false} // Disable Ant Design's default pagination
            onChange={handleTableChange}
            locale={{
              emptyText: <CustomSkeletonTable columnCount={columns?.length} />,
            }}
          />

          {/* Your Custom Pagination Component */}
        </div>
      </div>
      <div className={style.tabelpaginationDiv}>
        <PaginationNav
          pageCount={pageCount}
          totalPage={totalPage}
          setPageCount={setPageCount}
          bookingPageCount={pageSize} // Page size or items per page
        />
      </div>
    </>
  );
};

export default AllStudioDetail2;
