import React from "react";
import style from "../../pages/admin/studios/studio.module.css";
import { TimePicker } from "antd";

function CustomTimePicker({
  name,
  value = "",
  onChange,
  id,
  htmlFor,
  label,
  placeholder = "Select time",
  options = [],
  defaultOption,
  error,
  touched,
  disabled,
  customStyle = {},
  is24Hour = false, // New prop to control the format
  ...otherProps // Spread other props for flexibility
}) {
  const displayData = (time, timeString) => {
    console.log("Selected Time:", timeString);
    console.log("Time:", time);
  };

  return (
    <div className={style.customInput}>
      {label && (
        <label htmlFor={htmlFor} className={style.label}>
          {label}
        </label>
      )}
      <TimePicker
        name={name}
        use12Hours={!is24Hour} // Use 12-hour format if is24Hour is false
        format={is24Hour ? "HH:mm" : "h:mm a"} // Adjust format based on is24Hour
        onChange={(time, timeString) => {
          displayData(time, timeString);
          if (onChange) onChange(time, timeString); // Pass value to parent
        }}
        value={value}
        disabled={disabled}
        placeholder={placeholder}
        id={id}
        style={{ minHeight: "4.5vh" }}
        aria-labelledby={id}
        aria-invalid={!!error && !!touched}
        {...otherProps} // Include other props for future enhancements
      />
      {error && touched && (
        <p className={style.error} role="alert">
          {error}
        </p>
      )}
    </div>
  );
}

export default CustomTimePicker;
