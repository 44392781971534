import React, { useRef, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Divider, Input, Select, Space } from "antd";
import style from "../../pages/admin/studios/studio.module.css";

function CustomMultipleSelect({
  label = "Select Options",
  placeholder = "Select one or more options",
  optionsList = [],
  selectedItems = [],
  setSelectedItems = () => {},
  error,
  touched,
  onBlur,
  name,
  addOptions = true,
  htmlFor,
  value = "",
  icon,
  disabled,
  iconPosition = "left", // left or right
}) {
  const [items, setItems] = useState(optionsList);
  const [newItem, setNewItem] = useState("");
  const inputRef = useRef(null);

  // Helper function to check if the options are strings or objects
  const isObjectArray = items.length > 0 && typeof items[0] === "object";

  const filteredItems = items?.filter(
    (item) =>
      !selectedItems.some((selected) =>
        isObjectArray ? selected?.value === item?.value : selected === item
      )
  );

  const handleAddItem = (e) => {
    e.preventDefault();
    const trimmedItem = newItem.trim();

    if (trimmedItem) {
      if (isObjectArray) {
        const newItemObject = { label: trimmedItem, value: trimmedItem };
        if (!items.some((item) => item.value === newItemObject.value)) {
          setItems([...items, newItemObject]);
        }
      } else {
        if (!items.includes(trimmedItem)) {
          setItems([...items, trimmedItem]);
        }
      }
      setNewItem("");
    }
    setTimeout(() => inputRef.current?.focus(), 0);
  };

  const handleInputChange = (e) => setNewItem(e.target.value);

  const handleSelectChange = (selectedValues) => {
    if (isObjectArray) {
      const updatedSelectedItems = selectedValues.map(
        (val) =>
          items.find((item) => item.value === val) || { value: val, label: val }
      );
      setSelectedItems(updatedSelectedItems);
    } else {
      setSelectedItems(selectedValues);
    }
  };

  const handleDeselect = (deselectedValue) => {
    if (isObjectArray) {
      const deselectedItem = selectedItems.find(
        (item) => item.value === deselectedValue
      );
      if (
        deselectedItem &&
        !items.some((item) => item.value === deselectedItem.value)
      ) {
        setItems([...items, deselectedItem]);
      }
      setSelectedItems(
        selectedItems.filter((item) => item.value !== deselectedValue)
      );
    } else {
      if (deselectedValue && !items.includes(deselectedValue)) {
        setItems([...items, deselectedValue]);
      }
      setSelectedItems(
        selectedItems.filter((item) => item !== deselectedValue)
      );
    }
  };

  return (
    <div className={style.customInput}>
      <label htmlFor={htmlFor}>
        {iconPosition.toLowerCase() === "left" && icon}
        {label}
        {iconPosition.toLowerCase() === "right" && icon}
      </label>
      <Select
        mode="multiple"
        style={{ minHeight: "4.5vh" }}
        name={name}
        disabled={disabled} 
        placeholder={placeholder}
        value={
          value ||
          selectedItems.map((item) => (isObjectArray ? item?.value : item))
        }
        onBlur={onBlur}
        onChange={handleSelectChange}
        onDeselect={handleDeselect}
        options={filteredItems.map((item) =>
          isObjectArray
            ? { value: item?.value, label: item.label }
            : { value: item, label: item }
        )}
        dropdownRender={(menu) => (
          <>
            {menu}
            {addOptions && (
              <>
                <Divider style={{ margin: "8px 0" }} />
                <Space style={{ padding: "0 8px 4px" }}>
                  <Input
                    placeholder="Add a new option"
                    ref={inputRef}
                    value={newItem}
                    onChange={handleInputChange}
                    onKeyDown={(e) => e.stopPropagation()}
                  />
                  <Button
                    type="text"
                    icon={<PlusOutlined />}
                    onClick={handleAddItem}
                  >
                    Add
                  </Button>
                </Space>
              </>
            )}
          </>
        )}
      />

      {error && touched && Array.isArray(error) ? (
        error.map((errObj, index) => (
          <div key={index}>
            {Object.entries(errObj).map(([key, message]) => (
              <p className={style.error} key={key}>
                {message}
              </p>
            ))}
          </div>
        ))
      ) : error && touched && typeof error === "string" ? (
        <p className={style.error}>{error}</p>
      ) : null}
    </div>
  );
}

export default CustomMultipleSelect;
