

// import store from "./redux/store";
import './App.scss';
import Routes from "./Router";
import "./assets/js/script.js"


if (process.env.NODE_ENV === 'production') {
  console.log = () => {};
  console.info = () => {};
  console.warn = () => {};
  console.error = () => {};
}
function App() {


  return (
    // <Provider store={store}>

        <Routes />
      
    // </Provider>
  );
}

export default App;
